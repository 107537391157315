<div class="product-container rounded px-4 py-3 text-dark-blue text-standard">
    <h1>Blog</h1>
    <p>Wir entwickeln Ihren individuellen und eleganten Blog, mit beliebigen Zusatzfunktionen wie beispielsweise Benutzerforen und Email-Verteilern.</p>

    <hr class="my-4">

    <div class="row justify-content-around mt-4">
        <div class="col-md-4 p-2">
            <img class="img-cover w-100 border border-primary rounded img-border-2px" src="../../../assets/img/blog-1.png" alt="immorechner">
        </div>
        <div class="col-md-4 p-2">
            <img class="img-cover w-100 border border-primary rounded img-border-2px" src="../../../assets/img/blog-2.png" alt="immorechner">
        </div>
        <div class="col-md-4 p-2">
            <img class="w-100 border border-primary rounded img-border-2px" src="../../../assets/img/blog-3.png" alt="immorechner">
        </div>
    </div>

    <hr class="my-4">

    <a target="_blank" class="a-blue" href="https://cryptonut.de/">
        <button type="button" class="btn btn-bright-green mb-2 mb-md-0">
            Live Demo
        </button>
    </a>

</div>