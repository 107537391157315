<div class="product-container rounded px-4 py-3 text-dark-blue text-standard">
    <h1>Immorechner</h1>
    <p>Immorechner ist eine elegante und einfach zu bedienende Webanwendung, mit der sich Renditekennzahlen eines Immobilieninvestments berechnen lassen.</p>

    <hr class="my-4">


    <div class="row justify-content-around mt-4">
        <div class="col-md-4 p-2">
            <img class="img-cover w-100 border border-primary rounded img-border-2px" src="../../../assets/img/immorechner-1.png" alt="immorechner">
        </div>
        <div class="col-md-4 p-2">
            <img class="img-cover w-100 border border-primary rounded img-border-2px" src="../../../assets/img/immorechner-2.png" alt="immorechner">
        </div>
        <div class="col-md-4 p-2">
            <img class="w-100 border border-primary rounded img-border-2px" src="../../../assets/img/immorechner-3.png" alt="immorechner">
        </div>
    </div>

    <hr class="my-4">

    <a target="_blank" class="a-blue" href="https://immorechner.org/">
        <button type="button" class="btn btn-bright-green mb-2 mb-md-0">
            Live Demo
        </button>
    </a>

</div>