<div class="container-fluid footer-container text-standard py-5 px-md-5">


    <div class="row row-footer">
        <div class="col-md-3 col-lg-3 col-xl-2 offset-xl-2">
            <h6 class="text-green-bright text-left text-standard">Adresse</h6>
            <hr class="hr-footer">
            <p class="text-footer">Diginut GmbH<br>Welsches 1<br>88239 Wangen<br>Deutschland</p>
        </div>
        <div class="col-md-5 col-lg-5 col-xl-4 mt-3 mt-md-0">
            <h6 class="text-green-bright text-left text-standard">Kontakt</h6>
            <hr class="hr-footer">
            <p class="text-footer">Telefon: +49 7522 9784500<br>Mobil: +49 152 29559556<br>Email: <a target="_blank" class="a-bright" href="mailto:giulianobaumann@mailbox.org">giulianobaumann@mailbox.org</a></p>
        </div>
        <div class="col-md-4 col-lg-4 col-xl-2 mt-3 mt-md-0">
            <h6 class="text-green-bright text-left text-standard">Über Uns</h6>
            <hr class="hr-footer">
            <p class="text-footer">
                <a target="" class="a-bright" routerLink="privacy-policy">Datenschutz</a>
                <br>
                <a target="" class="a-bright" routerLink="about">Impressum</a>
            </p>
        </div>
    </div>

    <p class="text-center text-footer text-copyright mt-3">© 2021 Diginut GmbH</p>

</div>