<nav class="navbar navbar-expand-lg navbar-light navbar-dg bg-light">

    <div class="container">

        <a class="navbar-brand" href="">
            <h3 class="mb-0 text-dark-blue"><strong> {{ title }}</strong> </h3>
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

        <div class="collapse navbar-collapse ml-md-5" id="navbarNavDropdown">
            <ul class="navbar-nav mr-auto text-standard">
                <li class="nav-item">
                    <a class="nav-link py-1 a-standard" routerLink="">Start</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link py-1 a-standard" routerLink="products">Produkte & Ideen</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link py-1 a-standard" routerLink="services">Services</a>
                </li>
            </ul>
            <hr class="hr-navbar">

            <a target="_blank" class="a-blue" href="mailto:giulianobaumann@mailbox.org">
                <button type="button" class="btn btn-bright-green mb-2 mb-md-0">
                    Sprechen Sie mit uns
                </button>
            </a>

        </div>
    </div>
</nav>