import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adautomation',
  templateUrl: './adautomation.component.html',
  styleUrls: ['./adautomation.component.css']
})
export class AdautomationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
