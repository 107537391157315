<div class="container mt-75 mb-100">

    <h1 class="my-4 text-dark-blue"><strong>Unsere Services</strong></h1>

    <p class="text-lg text-dark-blue w-md-75">Wir entwickeln für Sie moderne Softwarelösungen, Webseiten, Webanwendungen und Smartphone Apps und beraten Sie gerne in Fragen zu einzelnen Softwarekomponenten oder Komplettlösungen.</p>

</div>

<div class="container mb-md-100 text-standard">
    <hr class="w-100">
    <div class="row my-0 my-md-5 px-3 px-md-1">
        <div class="col-12 col-md-6 my-3 my-md-0">
            <div class="row">
                <div class="col-2">
                    <img src="../../assets/svg/fullstack.svg" class="icon-services mt-2">
                </div>
                <div class="col-10">
                    <p class="text-left text-dark-blue pl-2 pl-sm-0"><strong>Full Stack Services</strong><br>Backends, Web Apps & Native Apps</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 my-3 my-md-0">
            <div class="row">
                <div class="col-2">
                    <img src="../../assets/svg/hosting-deployment.svg" class="icon-services mt-2">
                </div>
                <div class="col-10">
                    <p class="text-left text-dark-blue pl-2 pl-sm-0"><strong>DevOps</strong><br>CI/CD Pipelines, Testing & Serveradministration</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row my-0 my-md-5 px-3 px-md-1">
        <div class="col-12 col-md-6 my-3 my-md-0">
            <div class="row">
                <div class="col-2">
                    <img src="../../assets/svg/sketch.svg" class="icon-services mt-2">
                </div>
                <div class="col-10">
                    <p class="text-left text-dark-blue pl-2 pl-sm-0"><strong>Individuelle Designs</strong><br>Webdesign & Logos</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 my-3 my-md-0">
            <div class="row">
                <div class="col-2">
                    <img src="../../assets/svg/digital-marketing.svg" class="icon-services mt-2">
                </div>
                <div class="col-10">
                    <p class="text-left text-dark-blue pl-2 pl-sm-0"><strong>Digital Marketing</strong><br>Social Media-, Google- und Email- Werbung Automatisierung</p>
                </div>
            </div>
        </div>
    </div>
    <hr class="w-100">

</div>


<div class="container-fluid px-md-5 mt-100 mb-100 text-standard">

    <div class="row px-0 p-xl-5 py-5">
        <div class="col-12 col-md-5 col-lg-4 offset-lg-1 order-2 order-md-1 pt-5 pt-md-3">
            <div class="row pb-4 d-flex justify-content-center">
                <div class="col-12 text-center">
                    <img src="../../assets/svg/database.svg" class="svg-standard">
                </div>
            </div>
            <div class="row pt-4 pt-md-2 pt-lg-4 d-flex justify-content-center">
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/blockchain.svg" class="svg-standard">
                </div>
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/loupe.svg" class="svg-standard">
                </div>
            </div>
        </div>
        <div class="col-12 mt-5 col-md-6 col-lg-5 mt-md-0 px-4 px-md-2 order-1 order-md-2">
            <h3 class="text-dark-blue text-md-center"><strong>Datenmanagement</strong></h3>
            <p class="text-dark-blue">Eine professionelle Datenstrukturierung und -verwaltung ist die Grundlage für interne Betriebsprozesse und jede webbasierte Anwendung und damit sehr wichtig. Wir entwickeln und implementieren für Sie sowohl klassische Datenmodelle für relationale
                oder nicht relational Datenbankmanagementsysteme als auch neue dezentrale Ansätze wie beispielsweise Ethereum basierte Blockchain Netzwerke. Dazu bieten wir auch die Integrationen von Volltextsuchmaschinen an.</p>
        </div>
    </div>

    <div>
        <div class="row d-flex">
            <div class="col-md-8 px-md-4 px-lg-0 px-xl-3 col-lg-5 col-xl-6 offset-md-2 offset-lg-3 offset-xl-3">
                <img src="../../assets/svg/arrow-left-to-right-down.svg" class="d-none d-md-block w-100">
            </div>
        </div>
    </div>

    <div class="row px-0 p-xl-5 py-5 pt-150">
        <div class="col-12 mt-5 col-md-6 col-lg-4 offset-md-1 offset-lg-2 col-xl-5 mt-md-0 px-4 px-md-2">
            <h3 class="text-dark-blue text-md-center"><strong>APIs & Microservices</strong></h3>
            <p class="text-dark-blue">Um die Kommunikation zwischen unterschiedlichen Softwarekomponenten, Systemen und Diensten zu ermöglichen, entwickeln wir für Sie REST Schnittstellen und andere Arten der „Maschine-zu-Maschine-Kommunikation“. Dazu bieten wir die Entwicklung
                von Microservices an, um ihre IT Infrastruktur bestmöglich zu vernetzen. Um einen sicheren und stabilen Zugriff auf Ihre Webanwendungen zu gewährleisten beraten wir sie gerne zusätzlich rund um das Thema Sever- Lastenverteilung.</p>
        </div>
        <div class="col-12 col-md-5 col-lg-4 pt-5 pt-md-3">
            <div class="row pb-4 d-flex justify-content-center">
                <div class="col-12 text-center">
                    <img src="../../assets/svg/settings.svg" class="svg-standard">
                </div>
            </div>
            <div class="row pt-4 pt-md-2 pt-lg-4 d-flex justify-content-center">
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/server.svg" class="svg-standard">
                </div>
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/cloud.svg" class="svg-standard">
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="row d-flex">
            <div class="col-md-8 px-md-4 px-lg-0 px-xl-3 col-lg-5 col-xl-6 offset-md-2 offset-lg-3 offset-xl-3">
                <img src="../../assets/svg/arrow-right-to-left-down.svg" class="d-none d-md-block w-100">
            </div>
        </div>
    </div>

    <div class="row px-0 p-xl-5 py-5 pt-150">
        <div class="col-12 col-md-5 col-lg-4 offset-lg-1 order-2 order-md-1 pt-5 pt-md-3">
            <div class="row pb-4 d-flex justify-content-center">
                <div class="col-12 text-center">
                    <img src="../../assets/svg/smartphone.svg" class="svg-standard">
                </div>
            </div>
            <div class="row pt-4 pt-md-2 pt-lg-4 justify-content-center">
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/desktop.svg" class="svg-standard">
                </div>
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/tablet.svg" class="svg-standard">
                </div>
            </div>
        </div>
        <div class="col-12 mt-5 col-md-6 col-lg-5 mt-md-0 px-4 px-md-2 order-1 order-md-2">
            <h3 class="text-dark-blue text-md-center"><strong>Frontends</strong></h3>
            <p class="text-dark-blue">Eine gute Benutzererfahrung auf jeglichen Endgeräten ist der Schlüssel zur Kundenzufriedenheit. Wir entwickeln für Sie statische und interaktive Webanwendungen sowie native Smartphone Apps für Android und IOS und entwerfen für Sie individuelle
                Designs. Zusätzlich entwickeln wir auf Sie zugeschnittene Strategien und Automatisierungsprozesse für digitales Marketing, damit Sie Ihre Produkte effizient vermarkten können.</p>
        </div>
    </div>

    <div>
        <div class="row d-flex">
            <div class="col-md-8 px-md-4 px-lg-0 px-xl-3 col-lg-5 col-xl-6 offset-md-2 offset-lg-3 offset-xl-3">
                <img src="../../assets/svg/arrow-left-to-right-down.svg" class="d-none d-md-block w-100">
            </div>
        </div>
    </div>

    <div class="row px-0 p-xl-5 py-5 pt-150">
        <div class="col-12 mt-5 col-md-6 col-lg-4 offset-md-1 offset-lg-2 col-xl-5 mt-md-0 px-4 px-md-2">
            <h3 class="text-dark-blue text-md-center"><strong>DevOps</strong></h3>
            <p class="text-dark-blue">Um eine Anwendungen online schalten zu können, müssen alle Architekturkomponenten auf endsprechende Server und Cloud Dienstleister verteilt (deployt) werden. Wir entwickeln für Sie Automatisierungsprozesse für Themen wie Continuous Integration,
                Continuous Delivery und Continuous Deployment. Damit kann die dynamische Integration von zukünftigen neuen Funktionalitäten für ihre Anwendung sichergestellt werden.</p>
        </div>
        <div class="col-12 col-md-5 col-lg-4 pt-5 pt-md-3">
            <div class="row pb-4 d-flex justify-content-center">
                <div class="col-12 text-center">
                    <img src="../../assets/svg/devops-1.svg" class="svg-standard">
                </div>
            </div>
            <div class="row pt-4 pt-md-2 pt-lg-4 justify-content-center">
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/devops-2.svg" class="svg-standard">
                </div>
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/devops-3.svg" class="svg-standard">
                </div>
            </div>
        </div>
    </div>

</div>