import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './start/start.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { ProductsComponent } from './products/products.component';
import { ServicesComponent } from './services/services.component';
import { WebgisComponent } from './products/webgis/webgis.component';
import { ImmorechnerComponent } from './products/immorechner/immorechner.component';
import { AdautomationComponent } from './products/adautomation/adautomation.component';
import { AudioadminComponent } from './products/audioadmin/audioadmin.component';
import { BlogComponent } from './products/blog/blog.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
//import { APP_BASE_HREF } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    NavbarComponent,
    AboutComponent,
    FooterComponent,
    DatenschutzComponent,
    ProductsComponent,
    ServicesComponent,
    WebgisComponent,
    ImmorechnerComponent,
    AdautomationComponent,
    AudioadminComponent,
    BlogComponent,
    ScrollToTopComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  //providers: [{provide: APP_BASE_HREF, useValue: '/'}],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
