<div class="container mt-75 mb-150">

    <h1 class="my-4 text-dark-blue mt-4"><strong>Unsere Produkte</strong></h1>

    <p class="text-lg w-md-75 text-dark-blue">Lassen Sie sich von unseren Produkten inspirieren oder kontaktieren Sie uns für eine auf sie zugeschnittene Individuallösung.</p>

    <app-webgis></app-webgis>
    <app-immorechner></app-immorechner>
    <app-adautomation></app-adautomation>
    <app-audioadmin></app-audioadmin>
    <app-blog></app-blog>

</div>