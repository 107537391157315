<div class="container mt-75 mb-100">

    <!-- Heading -->
    <h1 id="start-title-screen" class="my-4 text-dark-blue"><strong>Softwarelösungen auf Sie zugeschnitten</strong></h1>

    <div class="text-dark-blue text-xl">
        <h3 class="mb-4">
            <strong>
            Wir digitalisieren Ihr Unternehmen und
            </strong>
        </h3>
        <p class="ml-3"><span class=""><img class="arrow-list-symbol" src="../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;entwickeln Webseiten und Anwendungen für Sie.</strong></p>
        <p class="ml-3"><span class=""><img class="arrow-list-symbol" src="../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;strukturieren Ihre Daten, Software und Services.</strong></p>
        <p class="ml-3"><span class=""><img class="arrow-list-symbol" src="../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;machen Ihre Systeme integrierbar.</strong></p>
        <p class="ml-3"><span class=""><img class="arrow-list-symbol" src="../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;optimieren Ihre Betriebsabläufe.</strong></p>
        <p class="ml-3"><span class=""><img class="arrow-list-symbol" src="../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;bringen Ihre lokalen Systeme ins Web und Smartphone.</strong></p>

    </div>

    <hr class="my-100">

    <div class="row">
        <div class="col-12 col-lg-5 order-2 order-lg-1">
            <p class="text-lg text-dark-blue">Von geografischen Informationssystemen und Business Intelligence über individuelle Blogs und Webanwendungen bis hin zur Blockchain Technologie. Lassen Sie sich von unseren Produktideen und Projekten inspirieren oder kontaktieren Sie uns für
                eine auf Sie zugeschnittene Individuallösung.</p>

            <a class="a-blue mb-2 mb-md-0" routerLink="products">
                <button type="button" class="btn btn-bright-green">
                    Produkte & Ideen
                </button>
            </a>

        </div>
        <div class="col-12 col-lg-7 order-1 order-lg-2 mb-3">
            <video class="w-100" id="webgis-overview-video" controls="false" autoplay loop muted>
                <source src="../../assets/video/webgis-overview.mp4" type="video/mp4">
            </video>
        </div>
    </div>

    <hr class="my-100">

    <div class="row mb-150">
        <div class="col-12 col-lg-4 mb-4">
            <div class="row pb-4 d-flex justify-content-center">
                <div class="col-12 text-center">
                    <img src="../../assets/svg/settings.svg" class="svg-standard">
                </div>
            </div>
            <div class="row pt-4 pt-md-2 pt-lg-4 d-flex justify-content-center">
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/server.svg" class="svg-standard">
                </div>
                <div class="col-5 col-lg-5 col-xl-4 text-center">
                    <img src="../../assets/svg/cloud.svg" class="svg-standard">
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-7 offset-lg-1 mb-4">
            <p class="text-lg text-dark-blue">Neben unseren Produkten entwickeln wir für Sie einzelne Softwarekomponenten oder Komplettlösungen in den Bereichen Datenmanagement, APIs & Microservices, Frontends und DevOps oder beraten Sie in diesen Bereichen.</p>

            <a class="a-blue mb-2 mb-md-0" routerLink="services">
                <button type="button" class="btn btn-bright-green">
                    Services
                </button>
            </a>

        </div>
    </div>

</div>