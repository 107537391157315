import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webgis',
  templateUrl: './webgis.component.html',
  styleUrls: ['./webgis.component.css']
})
export class WebgisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
