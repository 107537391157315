<div class="product-container rounded px-4 py-3 text-dark-blue text-standard">
    <h1>WebGIS</h1>
    <p>Diginut WebGIS bringt das klassische geografisches Informationssystem vom lokalen Rechenzentrum in die Cloud und von der nativen Desktopanwendung in den Webbrowser. Dadurch lassen sich Ihre Anwendungen beliebig skalieren und Zugriffe auf Ihre Daten
        sind flexibel von überall zu jeder Zeit möglich.</p>
    <p>Durch den modularen Charakter unseres WebGIS, kombiniert mit auf Sie angepasste Ergänzungen liefern wir Ihnen ein ideales Produkt, genau für Ihre Zwecke.</p>

    <div class="row mt-4">
        <div class="col-xl-6 mb-4 mb-xl-1">
            <h4>Module</h4>
            <hr>
            <p class="text-lg ml-3"><span class=""><img class="arrow-list-symbol"
                        src="../../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;Geodaten -
                    Visualisierung</strong></p>
            <p class="text-lg ml-3"><span class=""><img class="arrow-list-symbol"
                        src="../../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;Geodaten -
                    Analysewerkzeuge</strong></p>
            <p class="text-lg ml-3"><span class=""><img class="arrow-list-symbol"
                        src="../../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;Geodaten -
                    Editierung</strong></p>
            <p class="text-lg ml-3"><span class=""><img class="arrow-list-symbol"
                        src="../../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;Visualisierung
                    klassischer Daten</strong></p>
            <p class="text-lg ml-3"><span class=""><img class="arrow-list-symbol"
                        src="../../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;Editierung klassischer
                    Daten</strong></p>
        </div>
        <div class="col-xl-6">
            <h4>Individuelle Komponenten</h4>
            <hr>
            <p class="text-lg ml-3"><span class=""><img class="arrow-list-symbol"
                        src="../../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;Aufbereitung von
                    Geodaten</strong></p>
            <p class="text-lg ml-3"><span class=""><img class="arrow-list-symbol"
                        src="../../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;Entwicklung von
                    Geodaten - Analysewerkzeugen</strong></p>
            <p class="text-lg ml-3"><span class=""><img class="arrow-list-symbol"
                        src="../../../assets/svg/arrow-list-symbol.svg"></span><strong>&nbsp; &nbsp;Entwicklung von
                    Analysewerkzeugen für klassische Daten</strong></p>
        </div>
    </div>

    <hr class="my-4">

    <h4>Beispielanwendung</h4>
    <p class="text-lg text-dark-blue"><strong>WebGIS - Pollen Map</strong> ist eine Anwendung mit der man den Pollenflug anhand von Pflanzen- und Wetterdaten in Echtzeit prognostizieren kann. Basierend auf dieser Prognose, kann eine "Anti - Pollen - Navigation" berechnet werden. Außerdem
        kann die Verteilung allergenen Bäume für bestimmte Gebiete analysiert werden.</p>

    <hr class="my-4">

    <div class="row mt-4">
        <div class="col-md-6 p-2">
            <video class="w-100 border border-primary rounded img-border-2px" controls="false" autoplay loop>
                <source src="../../../assets/video/webgis-overview.mp4" type="video/mp4">
            </video>
        </div>
        <div class="col-md-6 p-2">
            <img class="w-100 border border-primary rounded img-border-2px" src="../../../assets/img/webgis-technologies.png" alt="immorechner">
        </div>
    </div>

    <hr class="my-4">

    <a target="_blank" class="a-blue" href="https://pollenmap.diginut.de/">
        <button type="button" class="btn btn-bright-green mb-2 mb-md-0">
            Live Demo
        </button>
    </a>

</div>