<div class="product-container rounded px-4 py-3 text-dark-blue text-standard">
    <h1>Werbeplattform</h1>
    <p>Unserer Werbeplattform macht Webseitenbetreibern unabhängig von großen Werbekonzernen. Die Werbeerlöse müssen nicht mehr mit Google und Co. geteilt werden.</p>

    <hr class="my-4">

    <div class="row justify-content-around mt-4">
        <div class="col-md-12 p-2">
            <img class="img-cover w-100 border border-primary rounded img-border-2px" src="../../../assets/img/adautomation-1.png" alt="immorechner">
        </div>
    </div>

    <hr class="my-4">

    <a target="_blank" class="a-blue" href="mailto:giulianobaumann@mailbox.org">
        <button type="button" class="btn btn-bright-green mb-2 mb-md-0">
            Sprechen Sie mit uns
        </button>
    </a>

</div>