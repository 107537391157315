<div class="container container-about text-standard mt-75 mb-150 px-5 px-md-3">

    <h1 class="my-5 text-dark-blue"><strong>Impressum</strong></h1>

    <div class="row my-4">
        <div class="col-lg-6">

            <p class="text-dark-blue">Diginut GmbH<br>Welsches 1<br>88239 Wangen</p>
            <p class="text-dark-blue"><span><strong>Telefon:</strong></span> +49 7522 9784500<br><span><strong>Mobile:</strong></span> +49 152 29559556</p>
            <p class="text-dark-blue"><span><strong>E-Mail:</strong></span>
                <a target="_blank" class="text-dark-blue" href="mailto:giulianobaumann@mailbox.org"> giulianobaumann@mailbox.org</a>
                <br><span><strong>Internet:</strong></span>
                <a target="_blank" class="text-dark-blue" href="https://www.diginut.de"> www.diginut.de</a>
            </p>

            <p class="text-dark-blue"><span><strong>Geschäftsführer:</strong></span> Giuliano Baumann</p>
            <p class="text-dark-blue"><span><strong>Registergericht:</strong></span> Amtsgericht Ulm HRB 739101</p>
            <p class="text-dark-blue"><span><strong>Umsatzsteuer-ID:</strong></span><br>gemäß § 27 a Umsatzsteuergesetz: DE 326912078</p>

        </div>
        <div class="col-lg-6 text-dark-blue">

            <p class="text-dark-blue">
                <span><strong>Inhaltlich Verantwortlicher:</strong></span> gemäß § 10 Absatz 3 MDStV:<br>Giuliano Baumann (Diginut GmbH)
            </p>
            <p class="text-dark-blue">
                <strong>Haftungshinweis:</strong> Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            </p>

            <a class="a-blue mb-2 mb-md-0" routerLink="/privacy-policy">
                <button type="button" class="btn btn-bright-green">
                Datenschutzerklärung
              </button>
            </a>

        </div>
    </div>

</div>